<template>
  <div>
    <div class="compont_p">积分兑换记录</div>

    <el-form :inline="true" style="margin-top: 20px">
      <el-form-item label="商品名称:">
        <el-input v-model="title" clearable></el-input>
      </el-form-item>
      <el-form-item label="兑换时间:">
        <el-date-picker v-model="time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
      </el-form-item>
      <el-form-item label="发放状态:">
        <el-select v-model="ffzt" placeholder="请选择" clearable>
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="custombtn" @click="getPoints">筛选</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData">
      <el-table-column prop="bz" label="商品名称" align="center" />
      <el-table-column prop="jfsl" label="消费积分" align="center" />
      <el-table-column prop="addtime" label="兑换时间" align="center" />
      <el-table-column prop="address.shr" label="收货人" align="center" />
      <el-table-column prop="address.shdh" label="收货电话" align="center" />
      <el-table-column prop="address.shdz" label="收货地址" align="center" />
      <el-table-column prop="ffztstr" label="发放状态" align="center" />
    </el-table>
    <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      tableData: [],
      options: [],
      title: "", //商品名称
      time: "",
      ffzt: "",
      total: 0,
      page: 1,
      page_size: 10,
    };
  },
  created() {
    this.getPoints();
    this.$api("account.getPointsffzt").then((res) => {
      this.options = res.data;
    });
  },
  methods: {
    getPoints() {
      this.$api("account.getPointsRecord", {
        title: this.title,
        qssj: this.time ? Date.parse(this.time[0]) : "",
        jssj: this.time ? Date.parse(this.time[1]) : "",
        ffzt: this.ffzt,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getPoints();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getPoints();
    },
  },
};
</script>
